<template>
  <div>
    <Toast position="top-right" />

    <div
      class="d-flex justify-content-center align-items-center h-75"
      v-if="carregando"
    >
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>
    <div
      v-else
      class="d-flex flex-column justify-content-between align-items-between h-100"
    >
      <div
        class="d-flex align-items-center row"
        style="margin-top: 8px; margin-left: -5px"
      >
        <div class="col-4 w-100">
          <label for="nome">Nome ou ID</label>
          <b-form-input
            v-model="filtros_consulta.nome"
            placeholder="Buscar por Título ou ID"
            class="mr-2"
            @input="filtrarConsulta"
          ></b-form-input>
        </div>
        <div class="col-4 w-100">
          <label for="md_consulta">Modelo</label>
          <MultiSelect
            v-model="filtros_consulta.modelo"
            :options="opcoesModelos"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            placeholder="Filtrar por Modelo"
            class="w-100"
            @input="filtrarConsulta"
            optionLabel="text"
            optionValue="value"
          ></MultiSelect>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-between h-100 w-100">
        <b-table
          id="tabelaConsultas"
          striped
          small
          :current-page="currentPage"
          :fields="fields"
          :items="consultasChatbot_filtradas"
          :per-page="selectQuant.value"
          responsive="sm"
          hover
          primary-key="modelo_campanha_id"
          @row-clicked="detalhes"
          sort-by="cmi_ativo"
          :sort-desc="true"
        >
          <template #cell(chatbot_campanha_id)="data">
            <label class="mt-3">
              {{ data.value || "-" }}
            </label>
          </template>

          <template #cell(md_descricao)="data">
            <label class="mt-3">
              {{ data.value || "-" }}
            </label>
          </template>

          <template #cell(opcoes)="row">
            <b-dropdown
              no-caret
              variant="outline-secondary"
              id="dropdown-dropleft"
              dropleft
              class="border-0"
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <b-dropdown-item href="#" @click="editarConsultaItem(row.item)"
                >Editar</b-dropdown-item
              >

              <b-dropdown-item
                @click="mostrarDeletarConsultaItemSwal(row.item)"
                href="#"
                >Remover</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex w-100">
          <b-row class="w-100">
            <b-col md="4"
              ><b-form-select
                v-model="selectQuant.value"
                :options="selectQuant.options"
              ></b-form-select
            ></b-col>
            <b-col md="8"
              ><b-pagination
                v-model="currentPage"
                :total-rows="consultasChatbot_filtradas.length"
                :per-page="selectQuant.value"
                aria-controls="tabelaConsultas"
                align="fill"
                @change="salvarConfiguracoes"
              ></b-pagination
            ></b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MultiSelect from "primevue/multiselect";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import store from "@/core/services/store/store";
import Swal from "sweetalert2";

export default {
  components: {
    Toast,
    MultiSelect
  },
  data() {
    return {
      consultasChatbot: [],
      consultasChatbot_filtradas: [],
      filtros_consulta: {
        nome: null,
        modelo: null
      },
      opcoesModelos: [],
      consultaItemSelecionado: {},
      campanhaSelecionada: {},
      campanhaDeletarNome: "",
      carregando: false,
      fields: [
        {
          key: "chatbot_campanha_id",
          label: "ID",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "80px !important" }
        },
        {
          key: "cca_nome",
          label: "Título da Consulta",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "md_descricao",
          label: "Modelo",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "md_descricao",
          label: "Descrição",
          sortable: true,
          class: "cursor-pointer align-middle"
        },
        {
          key: "opcoes",
          label: "Opções",
          sortable: false,
          //   class: "text-center",
          thStyle: { width: "60px !important" }
        }
      ],
      // itens: [],
      data: {},
      currentPage: 1,
      perPage: 10,
      itemSelecionado: {},
      selectQuant: {
        value: "10",
        options: [
          {
            text: "Exibir 10 registros por página",
            value: "10"
          },
          {
            text: "Exibir 15 registros por página",
            value: "15"
          },
          {
            text: "Exibir 30 registros por página",
            value: "30"
          }
        ]
      }
    };
  },

  methods: {
    editarConsultaItem(consultaItem) {
      this.$router.push({
        name: "automacao.consulta.editar",
        params: { consultaItem: consultaItem }
      });
    },
    pegarConsultasChatbot() {
      ApiService.get(API_LINKS.chatbot_consulta).then((response) => {
        this.consultasChatbot = response.data.campanhas;
        this.consultasChatbot_filtradas = response.data.campanhas;

        const aux = {};
        for (let c of response.data.campanhas) {
          aux[c.md_descricao] = c.md_descricao;
        }
        for (let i in aux) {
          this.opcoesModelos.push({ value: i, text: i });
        }
      });
    },
    filtrarConsulta() {
      let linhas_filtradas = this.consultasChatbot;
      if (this.filtros_consulta.nome) {
        const pesquisar = this.filtros_consulta.nome.toLowerCase();
        linhas_filtradas = this.consultasChatbot.filter(
          (f) =>
            f.cca_nome.toLowerCase().includes(pesquisar) ||
            `${f.chatbot_campanha_id}`.includes(pesquisar)
        );
      }

      if (
        Array.isArray(this.filtros_consulta.modelo) &&
        this.filtros_consulta.modelo.length > 0
      ) {
        const arrModelos = this.filtros_consulta.modelo;

        linhas_filtradas = this.consultasChatbot.filter((f) =>
          arrModelos.includes(f.md_descricao)
        );
      }

      this.consultasChatbot_filtradas = linhas_filtradas;
    },
    mostrarDeletarConsultaItemSwal(consultaItem) {
      // this.campanhaSelecionada = campanha;
      this.consultaItemSelecionado = consultaItem;
      Swal.fire({
        title:
          'Você deseja remover a consulta "' +
          this.consultaItemSelecionado.cca_nome +
          '"?',
        text: "Não será possivel reverter essa ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Consulta Removida",
            `Consulta "${this.consultaItemSelecionado.cca_nome}" removida.`,
            "success"
          );
          ApiService.delete(
            API_LINKS.chatbot_consulta +
              "/" +
              this.consultaItemSelecionado.chatbot_campanha_id
          ).then((_) => {
            this.pegarConsultasChatbot();
          });
        }
      });
    },
    detalhes(item) {
      this.consultaItemSelecionado = item;
      // redirect to editar consulta
      this.$router.push({
        name: "automacao.consulta.editar",
        params: {
          consultaItem: this.consultaItemSelecionado
        }
      });
    },
    salvarConfiguracoes(currentPage) {
      store.state.paginacao = {
        perPage: this.selectQuant.value,
        currentPage: currentPage,
        licenciadaSelecionadaFiltrada: this.licenciadaSelecionadaFiltrada
      };
    }
  },
  mounted() {
    this.pegarConsultasChatbot();
  },
  watch: {
    "selectQuant.value"(newValue) {
      this.salvarConfiguracoes(this.currentPage);
    }
  }
};
</script>

<style scoped>
.modal-deletar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.camp-center {
  display: flex;
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.p-multiselect-trigger-icon.pi.pi-chevron-down::before {
  content: none;
}

table#tabelaConsultas .flip-list-move {
  transition: transform 0.5s;
}

.status-on {
  background-color: rgb(97, 191, 3);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
.status-off {
  background-color: rgb(214, 21, 21);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}
</style>
